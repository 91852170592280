import { useEffect, useRef } from "react";
import "./Services.css";
import { useParams } from "react-router-dom";
import Service from "../components/services/Service";

const Services = () => {
  const basicCleaningRef = useRef(null);
  const mirrorCleaningRef = useRef(null);
  const cleaningAfterRepairingRef = useRef(null);
  const softFurnitureCleaningRef = useRef(null);
  const subscriptionCleaningRef = useRef(null);
  const personalizedCleaningRef = useRef(null);
  const cleaningSetRef = useRef(null);
  const refs = {
    1: basicCleaningRef,
    2: mirrorCleaningRef,
    3: cleaningAfterRepairingRef,
    4: softFurnitureCleaningRef,
    5: subscriptionCleaningRef,
    6: personalizedCleaningRef,
    7: cleaningSetRef,
  };

  const { id } = useParams();

  const selectedSection = refs[id];

  useEffect(() => {
    if (selectedSection) {
      selectedSection.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedSection]);
  return (
    <>
      <div className="page-container">
        <h1 className="page-title title">Услуги</h1>
        <div className="services-container">
          <Service
            image={require("../img/services/1.webp")}
            serviceRef={basicCleaningRef}
            title={"Основно почистване"}
            priceAsText={"3.5 лв./кв.м"}
          >
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Обезпрашаване на всички повърхности</span>
            </div>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Сухо и влажно почистване на подови настилки</span>
            </div>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Почистване на дограма и прозорци-отвътре и отвън</span>
            </div>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Премахване на паяжини</span>
            </div>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Почистване на електрически уреди и осветителни тела</span>
            </div>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Почистване на баня и тоалетна</span>
            </div>
          </Service>

          <Service
            serviceRef={subscriptionCleaningRef}
            image={require("../img/services/7.webp")}
            title={"Абонаментно почистване"}
            priceAsText={"По договаряне"}
          >
            <p>
              Мечтаеш за повече свободно време и да се отървеш от досадните
              почистващи препарати и консумативи, а в същото време искаш да
              спестиш средства?
            </p>
            <p>
              Всичко това е възможно, защото ние от
              <span className="highlighted"> CleanDirtybg</span> сме тук, за да
              помогнем. Единственото, което трябва да направиш е да се свържеш с
              нас, за да се извърши оглед и да се определи удобно за теб време.
            </p>

            <h4>
              <span className="highlighted">Услугата може да се извършва:</span>
            </h4>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Ежедневно</span>
            </div>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Седмично</span>
            </div>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Двуседмично</span>
            </div>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Месечно</span>
            </div>

            <h4>
              <span className="highlighted">Какво включва услугата?</span>
            </h4>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Почистване с прахосмукачка</span>
            </div>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Почистване на подови настилки</span>
            </div>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Забърсване на прах</span>
            </div>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Премахване на петна и замърсявания</span>
            </div>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Премахване на паяжини </span>
            </div>
          </Service>

          <Service
            serviceRef={mirrorCleaningRef}
            image={require("../img/services/4.webp")}
            title={"Почистване на прозорци"}
            priceAsText={"5 лв./кв.м"}
          >
            <p>
              Чистите прозорци играят важна роля в ежедневието ни. Те позволяват
              на светлината да проникне в стаите ни и ни предоставят изглед към
              външния свят. Но понякога поддържането им е трудна задача.
            </p>
            <p>
              Забързаното ни ежедневие рядко ни дава шанс да се погрижим
              качествено за нашите прозорци и това е нормално. За щастие има
              професионалисти, като нас, които ще се справят вместо вас.
              Единственото което трябва да направите е да се свържете с нас. За
              да видите света отвън по съвсем нов начин.
            </p>
            <h4>
              <span className="highlighted">Какво включва услугата?</span>
            </h4>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Почистване и полиране на прозорци</span>
            </div>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Почистване на дограма/первази</span>
            </div>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Почистване на гумени уплатнения</span>
            </div>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Премахване на лепенки след ремонт</span>
            </div>
          </Service>

          <Service
            serviceRef={cleaningAfterRepairingRef}
            image={require("../img/services/6.webp")}
            title={"Почистване след ремонт"}
            priceAsText={"5 лв./кв.м"}
          >
            <p>
              Успешното приключване на строителна работа или ремонтни дейности е
              вълнуващо за всеки собственик. Но това съвсем не е последната
              стъпка при цялостното завършване или реновиране на обекта.
            </p>
            <p>
              Почистването след ремонт е също толкова важно, тъй като
              най-вероятно всичко ще е покрито с прах и ще има отломки и боклуци
              навсякъде. Позволете си лукса да изпипате всичко до последният
              детайл с нашите услуги за почистване след ремонт.
            </p>
            <p>
              Ние от <span className="highlighted">CleanDirtybg</span> ще
              направим изживяването при новото нанасяне още по-наситено и
              изпълнено с положителни емоции. И всичко това без да се налага да
              се затормозявате с всичко, което иначе ви очаква.
            </p>
            <h4>
              <span className="highlighted">Какво включва услугата?</span>
            </h4>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Събиране на строителната прах от всички повърхности</span>
            </div>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Измиване на огледалата и стъклените повърхности</span>
            </div>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Измиване на прозорците, первазите и дограмата</span>
            </div>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Сухо и влажно почистване на подови настилки</span>
            </div>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>
                Отстраняване на остатъците от цимент, лепило, монтажна пяна, боя
              </span>
            </div>
          </Service>

          <Service
            serviceRef={softFurnitureCleaningRef}
            image={require("../img/services/5.webp")}
            title="Пране на мека мебел"
            priceAsText={"20 лв./седящо място"}
          >
            <p>
              Не... Не е нужно да заменяте старите мебели с нови. Това само ще
              увеличи разходите ви. Това, което може да направите е да се
              погрижите за мебелите, които вече притежавате. Всичко това е на
              един клик разстояние.
            </p>
            <p>
              Ние от <span className="highlighted">CleanDirtybg</span>{" "}
              разполагаме с екстракторна машина, която е в състояние да се
              справи със стари и упорити петна по вашите мебели. Вдъхнете отново
              живот на мебелите си и ще им се насладите така, както когато са
              били нови.
            </p>
            <h4>
              <span className="highlighted">Какво включва услугата?</span>
            </h4>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Прахосмучене на мебелната повърхност</span>
            </div>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Машинно изпиране на мебелната повърхност</span>
            </div>
          </Service>

          <Service
            serviceRef={personalizedCleaningRef}
            image={require("../img/services/3.webp")}
            title={"Извършване на услуги по желание на клиента"}
            priceAsText={"По договаряне"}
          >
            <p>
              Всяка услуга може да бъде индивидуализирана по желание на клиента.
            </p>
            <h4>
              <span className="highlighted">
                Други услуги, които могат да Ви бъдат от полза:
              </span>
            </h4>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Почистване след изнасяне</span>
            </div>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Почистване при нанасяне</span>
            </div>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Почистване преди и след приеми</span>
            </div>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Почистване преди и след събития</span>
            </div>
          </Service>

          <Service
            serviceRef={cleaningSetRef}
            image={require("../img/services/2.webp")}
            title={"Комплект за почистване след ремонт"}
            priceAsText={"120 лв."}
          >
            <h4>
              <span className="highlighted">Комплектът включва:</span>
            </h4>

            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Стълба 1.50 м. 1 бр.</span>
            </div>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Стъклочистачки</span>
            </div>
            <div className="advantage sub-advantage">
              <ion-icon class="icon" name="checkmark-done-outline"></ion-icon>
              <span>Стъклочистачка 35см. 1 бр.</span>
            </div>
            <div className="advantage sub-advantage">
              <ion-icon class="icon" name="checkmark-done-outline"></ion-icon>
              <span>Моп за стъклопочистване 35 см. 1 бр.</span>
            </div>
            <div className="advantage sub-advantage">
              <ion-icon class="icon" name="checkmark-done-outline"></ion-icon>
              <span>Нож за стъкло 1 бр.</span>
            </div>

            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Кофа с куки(22л. Размер:56х30х27 ) 1 бр.</span>
            </div>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Макетен нож 1 бр.</span>
            </div>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>
                Микрофибърни кърпи ( 6-почистващи 4-полиращи 40х40 ) 10 бр.
              </span>
            </div>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Четка за почистване 2 бр.</span>
            </div>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Метла и лопата 1 бр.</span>
            </div>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Микрофибърен моп 1 бр.</span>
            </div>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Винтоверт с 3 вида четки 1 бр.</span>
            </div>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Телескопична дръжка 2 м. 1 бр.</span>
            </div>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Прахосмукачка 1 бр.</span>
            </div>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Торби за боклук 5 бр.</span>
            </div>

            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Ръкавици 2 бр.</span>
            </div>
            <div className="advantage">
              <ion-icon class="icon" name="checkmark-outline"></ion-icon>
              <span>Препарат за различни видове повърхности</span>
            </div>
            <div className="advantage sub-advantage">
              <ion-icon class="icon" name="checkmark-done-outline"></ion-icon>
              <span>Ламинат и паркет</span>
            </div>
            <div className="advantage sub-advantage">
              <ion-icon class="icon" name="checkmark-done-outline"></ion-icon>
              <span>Стъкло</span>
            </div>
            <div className="advantage sub-advantage">
              <ion-icon class="icon" name="checkmark-done-outline"></ion-icon>
              <span>Метали</span>
            </div>
            <div className="advantage sub-advantage">
              <ion-icon class="icon" name="checkmark-done-outline"></ion-icon>
              <span>Плочки</span>
            </div>
            <div className="advantage sub-advantage">
              <ion-icon class="icon" name="checkmark-done-outline"></ion-icon>
              <span>Дърво</span>
            </div>
            <div className="cleaning-set-price-info">
              <p>Безплатна доставка в рамките на град Варна.</p>
              <p>Депозит в размер на 50% от общата сума.</p>
              <p>Препарата се таксува допълнително 3 лв./50 мл. концентрат.</p>
              <p>
                При повреда или некоректно ползване на атрикулите цената може да
                бъде променена.
              </p>
            </div>
          </Service>
        </div>
      </div>
    </>
  );
};

export default Services;
