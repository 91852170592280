import { useState } from "react";
import "./Gallery.css";
import { useEffect } from "react";
import ImagePopup from "../components/UI/ImagePopup";

const Gallery = () => {
  const [images, setImages] = useState([]);
  const [imgSrcPopup, setImgSrcPopup] = useState("");

  useEffect(() => {
    for (let i = 1; i <= 9; i++) {
      const img = require(`../img/gallery/${i}.webp`);

      setImages((state) => {
        return [...state, img];
      });
    }
  }, []);

  const imageClickHandler = (imgSrc) => {
    setImgSrcPopup(imgSrc);
  };

  const closeImagePopup = () => {
    setImgSrcPopup("");
  };

  return (
    <>
      <div className="page-container">
        <h1 className="page-title title">Галерия</h1>
        <div className="gallery-wrapper">
          {images?.map((x) => {
            return (
              <div
                onClick={() => {
                  imageClickHandler(x);
                }}
                key={x}
                className="gallery-img-container"
              >
                <img className="img" src={x} alt="My" />
              </div>
            );
          })}
        </div>
      </div>
      {imgSrcPopup && (
        <ImagePopup imageSrc={imgSrcPopup} closeImagePopup={closeImagePopup} />
      )}
    </>
  );
};

export default Gallery;
